import logo from "./logo.svg";
import "./App.css";
import ResponsiveCarousel from "./ResponsiveCarousel";
import { ArrowLeft, ArrowRight } from "react-feather";
import Slider from "./Slider";
import { useEffect, useState } from "react";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import Swiperone from "./Swiperone";
import Swipertwo from "./Swipertwo";
import Hero from "./components/Hero";
import Counter from "./components/Counter";
import Gsap from "./components/Gsap";
import SliderThree from "./Sliderthree";
import SpecialistSection from "./components/SpecialistsSection";
import ServicesSection from "./components/ServicesSection";
import ProductSection from "./components/ProductSection";
import ClientGrid from "./components/ClientGrid";

function App() {
  return (
    <div className="App">
      {/* <div
        style={{
          backgroundPositionX: "right !important",
          backgroundPositionY: "25%",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.2) 22.21%, rgba(80, 73, 73, 0.146) 88.95%), url('./photos/bg1.jpg')",
          backgroundRepeat: "no-repeat !important",
          backgroundSize: "cover",
        }}
        className="bg-fixed h-[100vh]"
      >
        <div className="md:w-[678px] md:pt-[252px] md:pt-[150px] 2xl:ml-[400px] 2xl:pt-[300px] pt-[300px] lg:ml-[140px] md:ml-[50px]">
          <p
            style={{
              fontWeight: 700,
            }}
            className="text-white text-[32px] md:text-[42px] md:text-left md:leading-tight leading-9"
          >
            Elevate Your Digital Transformation Landscape with <br />
            CBS’s Digital Solutions
          </p>
          <p
            style={{
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "27px",
            }}
            className="md:text-left mt-[16px] md:max-w-[450px] sm:text-center"
          >
            CBS’s Digital Solutions
          </p>
        </div>
      </div> */}
      <Hero />
      <div
        data-aos="fade-up"
        className="lg:p-[80px] md:p-[32px] md:py-[80px] px-[16px] py-[40px]"
      >
        <button
          style={{
            background:
              "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
            width: "90px",
            height: "30px",
          }}
          className="rounded-full text-[16px] border-1"
        >
          <span className="w-full">Features</span>
        </button>
        <p className="md:text-5xl md:text-[32px] text-[24px] font-simibold py-3">
          Our Strategic Values
        </p>
        <p className="text-[#C2C7D6] text-[16px] md:max-w-[800px] md:mx-auto">
          Our strategic value is by prioritizing to leverage technology
          innovation, agile methodologies and talent development which expertise
          to create products or services that address market needs effectively.
        </p>
        <div className="md:flex flex-wrap container items-center md:py-22 lg:h-96 mx-auto">
          <div
            className="md:w-1/3 max-w-[270px] mx-auto py-[32px] pb-[64px] md:py-0 text-center
           bg-center bg-no-repeat"
          >
            <img
              style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon1.png"
              alt=""
              className="mx-auto m-2"
            />
            <p className="text-2xl pt-[16px]">Cutting-Edge Innovation</p>
            <p className="pt-[24px] text-[#C2C7D6] text-[16px] ">
              Continuously developing new and innovative software solutions to
              stay ahead of the competition and meet evolving customer needs.
            </p>
          </div>
          <div
            style={{ background: "white" }}
            className="md:h-48 md:w-1 w-[201px] h-[1px] bg-white mx-auto"
          ></div>
          <div className="md:w-1/3 max-w-[270px] mx-auto py-[32px] pb-[64px] md:py-0 text-center bg-center bg-no-repeat">
            <img
              style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon2.png"
              alt=""
              className="mx-auto m-2"
            />
            <p className="text-2xl pt-[16px]">
              {/* Seamless <br /> Connectivity */}
              Agility
            </p>
            <p className="pt-[24px] text-[#C2C7D6] text-[16px] ">
              Being able to adapt quickly to changes in technology, market
              trends, and customer preferences to remain competitive and seize
              new opportunities.
            </p>
          </div>
          <div
            style={{ background: "white" }}
            className="md:h-48 md:w-1 w-[201px] h-[1px] bg-white mx-auto"
          ></div>
          <div className="md:w-1/3 max-w-[270px] mx-auto py-[32px] pb-[64px] md:py-0  text-center bg-center bg-no-repeat">
            <img
              style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon3.png"
              alt=""
              className="mx-auto m-2"
            />
            <p className="text-2xl pt-[16px]">Talent Development</p>
            <p className="pt-[24px] text-[#C2C7D6] text-[16px] ">
              Investing in recruiting and retaining top talent, as well as
              providing ongoing training and development opportunities to ensure
              a skilled and motivated workforce.
            </p>
          </div>
        </div>
      </div>

      <SpecialistSection /> 

      <ServicesSection />

      <ProductSection />

      <ClientGrid />

      {/* <div
        data-aos="fade-up"
        className=" md:h-[500px] md:py-[120px] md:px-[80px] xl:max-w-[1600px] xl:mx-auto"
      >
        <button
          style={{
            background:
              "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
            width: "100px",
            height: "30px",
          }}
          className="rounded-full text-[16px] border-1"
        >
          <span className="w-full">Our Client</span>
        </button>
        <p className="md:text-5xl text-[32px] font-simibold py-3">
          Trusted by Industry
        </p>
        <p className="text-wrap pt-[20px] mx-auto text-[#C2C7D6] md:w-[700px] w-[95%]">
          CBS take pride in being recognized as a trusted digital solutions
          partner by leading in the industry. Our commitment to excellence,
          innovation, and customer satisfaction has earned us the trust of
          businesses across.
        </p>
       
        <div className=" my-12">
          <Gsap className=" my-12" />
        </div>
      </div> */}
      {/* <div
        data-aos="fade-up"
        style={{ margin: "auto" }}
        className="border-white md:p-5 md:m-5 text-white max-w-[1500px]"
      >
        <button
          style={{
            background:
              "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
            width: "134px",
            height: "30px",
          }}
          className="rounded-full text-[16px] border-1"
        >
          <span className="w-full">Success Stories</span>
        </button>
        <Slider />
      </div> */}
      {/* <div
        style={{ overflow: "hidden" }}
        className="lg:flex items-center container  mx-auto mx-8"
      >
        <div
          data-aos="fade-right"
          className="md:w-[648px] max-w-[648px] md:m-12 m-5"
        >
          <div
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 215, 234, 0.27), rgba(0, 215, 234, 0.27))",
            }}
            className="relative border rounded ms-auto mr-[50px] flex flex-col justify-center items-start md:ps-10 ps-5 md:w-[270px] w-[150px] md:h-[150px] h-[85px]"
          >
            <p className="md:text-7xl text-[40px] font-semibold flex">
              <Counter endCount="100" />
              <span>+</span>
            </p>
            <p className="text-[#C2C7D6] md:text-[24px] text-[13px] flex items-center">
              <div className="border w-[8px] h-[25px] bg-[#65A30D] me-2"></div>
              <span>Happy Clients</span>
            </p>
            <img
              src="/photos/Icon10.png"
              alt=""
              className="mx-auto m-2 md:w-[70px] w-[30px] md:h-[70px] h-[30px] absolute md:top-[-30px] md:right-[-30px] top-[-15px] right-[-15px]"
            />
          </div>
          <div
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 215, 234, 0.27), rgba(0, 215, 234, 0.27))",
            }}
            className="relative border rounded mr-auto ml-[15px] md:mt-[-30px] mt-[-10px] flex flex-col justify-center items-start md:ps-10 ps-5 md:w-[350px] w-[198px] md:h-[150px] h-[85px]"
          >
            <p className="md:text-7xl text-[40px] font-semibold flex">
              {" "}
              <Counter endCount="200" />
              <span>+</span>
            </p>
            <p className="text-[#C2C7D6] md:text-[24px] text-[13px] flex items-center">
              <div className="border w-[8px] h-[25px] bg-[#65A30D] me-2"></div>
              <span>Projects Completed</span>
            </p>
            <img
              src="/photos/Icon11.png"
              alt=""
              className="mx-auto m-2 md:w-[70px] w-[30px] md:h-[70px] h-[30px] absolute md:top-[-30px] md:left-[-30px] top-[-15px] left-[-15px]"
            />
          </div>
          <div
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 215, 234, 0.27), rgba(0, 215, 234, 0.27))",
            }}
            className="relative border rounded ms-auto md:mr-[100px] mr-[50px] md:mt-[-20px] mt-[-10px] flex flex-col justify-center items-start md:ps-10 ps-5 md:w-[265px] w-[150px] md:h-[150px] h-[85px]"
          >
            <p className="md:text-7xl text-[40px] font-semibold flex">
              {" "}
              <Counter endCount="5" />
              <span>+</span>
            </p>
            <p className="text-[#C2C7D6] md:text-[24px] text-[13px] flex items-center">
              <div className="border w-[8px] h-[25px] bg-[#65A30D] me-2"></div>
              <span>Years in Business</span>
            </p>
            <img
              src="/photos/Icon12.png"
              alt=""
              className="mx-auto m-2 md:w-[70px] w-[30px] md:h-[70px] h-[30px] absolute md:top-[-30px] md:right-[-30px] top-[-15px] right-[-15px]"
            />
          </div>
          <div
            style={{
              background:
                "linear-gradient(0deg, rgba(0, 215, 234, 0.27), rgba(0, 215, 234, 0.27))",
            }}
            className="relative border rounded mr-auto md:ml-[80px] ml-[40px] md:mt-[-40px] mt-[-15px] flex flex-col justify-center items-start md:ps-10 ps-5 md:w-[320px] w-[183px] md:h-[150px] h-[85px]"
          >
            <p className="md:text-7xl text-[40px] font-semibold flex">
              {" "}
              <Counter endCount="98" />
              <span>%</span>
            </p>
            <p className="text-[#C2C7D6] md:text-[24px] text-[13px] flex items-center">
              <div className="border w-[8px] h-[25px] bg-[#65A30D] me-2"></div>
              <span>Customer Satisfaction</span>
            </p>
            <img
              src="/photos/Icon13.png"
              alt=""
              className="mx-auto m-2 md:w-[70px] w-[30px] md:h-[70px] h-[30px] absolute md:bottom-[-30px] bottom-[-15px] md:right-[-30px] right-[-15px]"
            />
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="lg:w-[40%] md:m-12 m-5 md:text-start md:p-6 pb-8 pt-12 md:pt-6"
        >
          <p
            className="md:text-[48px] text-[32px] font-bold"
            style={{ lineHeight: "58px" }}
          >
            Over 6 Years Experience
          </p>
          <p className="text-[#C2C7D6] mt-3 text-[16px]">
            Connected Business Solutions Company - CBS was established in 2018
            and providing Digital Solutions providers in Myanmar.
          </p>
          <a href="/contact">
            <button
              type="button"
              style={{
                background:
                  "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              }}
              className="w-[170px] h-[50px] mt-5 ms-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Contact us
            </button>
          </a>
        </div>
      </div> */}
      <div
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="max-w-[1280px] card2bg md:mx-[80px] md:my-[120px] lg:mx-auto p-8 rounded-xl md:mb-28 mb-12 mt-12  mx-[16px] md:mx-[0px]"
      >
        <p className="md:text-5xl text-[16px] font-bold py-3 mx-auto">
          Our Dedicated Team is Here <br />
          to provide you
        </p>
        <p className="md:text-[16px] text-[12px]">
          Requesting a demo is the perfect way to explore the features,
          benefits, and possibilities that our solution offers.
        </p>
        <div className="relative mx-auto my-3 md:h-[45px] h-[30px] md:w-[513px] w-[274px]">
          <input
            className="w-full h-full rounded-full text-black text-[12px] ps-3 md:text-[16px]"
            type="text"
            placeholder="Enter Your Email"
          />
          <button
            style={{
              background:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="md:w-40 w-[100px] md:h-[36px] h-[22px] rounded-full md:text-[16px] text-[10px] md:right-[6px] right-[5px]"
          >
            Request a Demo
          </button>
        </div>

        <p className="md:text-[14px] text-[12px] md:w-[400px] mx-auto">
          By clicking Sign Up you're confirming that you agree with our Terms
          and Conditions.
        </p>
      </div>
    </div>
  );
}

export default App;


{/* <div
data-aos="fade-up"
style={{ background: "#22282D" }}
className="items-center border-2 container mx-auto lg:p-4 xl:p-8  p-4 rounded-xl my-12 mx-2 w-[95%] md:w-[90%] lg:max-h-[600px] xl:max-h-[500px]"
>
<Swipertwo />
</div>
<div
data-aos="fade-up"
style={{ background: "#22282D" }}
className="items-center border-2 container mx-auto lg:p-4 xl:p-8 p-4 rounded-xl my-12 mx-2 w-[95%] md:w-[90%] lg:max-h-[600px] xl:max-h-[500px]"
>
<div style={{ textAlign: "left" }} className="border-white md:ps-2">
  <Swiperone />
</div>
</div>

<div
data-aos="fade-up"
className="border-white md:p-5 md:m-5 mb-8 md:mb-0 m-3  text-white md:w-[90%] md:mx-auto xl:max-w-[1600px]"
>
<SliderThree />
</div> */}