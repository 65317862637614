import React, { useState } from "react";

const ProductSection = () => {
  const products = [
    {
      title: "School Management",
      description: [
        `School management system (SMS) is a software application designed to streamline the administrative tasks of educational institutions, including schools, colleges, and universities.`,
        `It typically includes various modules to manage student information, academic records, staff details, finance, HR attendance & payroll, examinations, library management, transport management, receptionist, online admission and more.`,
        `Some key features and components typically found in a school management system.`,
      ],
      image: "/photos/productPhotos/school-management.png",
    },
    {
      title: "ERP/CRM",
      description: [
        `Our ERP system consolidates customer information, facilitating improved communication and fostering stronger relationships. With features such as contact management, sales tracking, automated tasks (quotation, invoice, purchase history, reminder), HR modules (leave, attendance, payroll, etc..), billing, project management and robust reporting analytics.`,
        `It can handle various payment methods, including credit and debit cards, mobile payments, and cash, ensuring a seamless checkout experience.`,
      ],
      image: "/photos/productPhotos/erp-crm.png",
    },
    {
      title: "Point of Sale (POS)",
      description: [
        `A Point of Sale (POS) system is a comprehensive solution designed to facilitate sales transactions and manage business operations efficiently.`,
        `It can handle various payment methods, including credit and debit cards, mobile payments, and cash, ensuring a seamless checkout experience.`,
        `With the advancement of technology, our POS now offers cloud-based solutions, allowing businesses to access their data remotely and make real-time updates.`,
      ],
      image: "/photos/productPhotos/pos.png",
    },
    {
      title: "Clinic Management",
      description: [
        `Clinic Management System is designed to streamline the administrative and clinical operations of healthcare clinics, medical practices, and outpatient facilities. `,
        `It encompasses various modules to manage patient appointments, billing and invoicing, inventory, staff scheduling, and more. `,
        `By automating administrative tasks and centralizing patient data, CMS empowers healthcare providers to deliver high-quality care and improve patient outcomes.`
      ],
      image: "/photos/productPhotos/clinic-management.png",
    },
    {
      title: "Condo/Tenant Management",
      description: [
        `A Tenant Management System is a comprehensive software solution designed to streamline the management of rental properties, condo and tenant interactions. Ideal for property managers, landlords, and real estate agencies, it simplifies tasks such as tracking rent payments, automated reminders for upcoming payments or lease expirations, and handling maintenance requests.`,
      ],
      image: "/photos/productPhotos/condo-management.png",
    },
    {
      title: "Hotel Management",
      description: [
        `A hotel management system (HMS) is designed efficiently managing their day-to-day operations.
 It combines various modules and functionalities to streamline tasks such as reservations, front desk operations, room management, billing, inventory, reporting, and more.`,
        `The system enables handle room reservations, check-ins, and check-outs seamlessly, while also managing guest preferences membership and loyalty programs.`,
      ],
      image: "/photos/productPhotos/hotel-management.png",
    },
    {
      title: "Ecommerce Management",
      description: [
        `Our e-commerce platform is designed to provide a seamless online shopping experience, connecting buyers with a wide range of products from various sellers. With an intuitive interface and powerful search functionality, users can easily browse, compare, and purchase products across categories such as fashion, electronics, home goods, and more.`,
      ],
      image: "/photos/productPhotos/ecommerce-management.png",
    },
    {
      title: "Mortgage Management",
      description: [
        `The Mortgage Management System is a comprehensive solution designed to streamline the entire mortgage process, from loan origination to servicing. Key features include automated loan application processing, credit and background checks, document management, and real-time status tracking. The system also supports payment scheduling, interest calculation, and compliance with regulatory standards, ensuring transparency and accuracy throughout the mortgage lifecycle.`,
      ],
      image: "/photos/productPhotos/mortgage-management.png",
    },
    {
      title: "SMS Gateway",
      description: [
        `Connected’s SMS gateway is that enables the sending and receiving messages between different Mobile Operators. It acts as a bridge between various messaging platforms, allowing users to send SMS messages from their computers, web applications, or other devices to phones, etc.
Allowing businesses and organizations for various purposes, such as Marketing, Notifications, and Authentication. `,
        `Key features include message queuing, delivery reports, two-way messaging, sender ID customization, keyword processing, and security measures.`,
      ],
      image: "/photos/productPhotos/sms-gateway.png",
    },
    {
      title: "Marketing Tools",
      description: [
        `Marketing Tools are powerful platforms designed to help businesses connect with your audience efficiently and effectively through two popular channels: SMS and email. These tools enable organizations to create, schedule, and manage marketing campaigns, announcements, and transactional messages with ease.`,
      ],
      image: "/photos/productPhotos/marketing-tools.png",
    },
    {
      title: "Cloud PBX",
      description: [
        `Cloud PBX is a virtual phone system hosted in the cloud, allowing businesses to manage their communication needs over the internet without the need for traditional hardware. It offers a scalable, cost-effective solution for handling internal and external calls from any location, perfect for remote or distributed teams.`,
        `Cloud PBX offers a flexible and cost-effective way to modernize your business communication.`,
      ],
      image: "/photos/productPhotos/cloud-pbx.png",
    },
    {
      title: "Payment Gateway",
      description: [
        `Connected’s payment gateway is a technology service that securely connects merchants' websites or applications to payment processors or banks, facilitating online transactions. It encrypts payment information provided by customers, forwards it for authorization, and relays the response to the merchant. Payment gateways play a crucial role in enabling secure and seamless online transactions, supporting various payment methods and ensuring compliance with security standards.`,
      ],
      image: "/photos/productPhotos/payment-gateway.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { title, description, image } = products[currentIndex];

  return (
    <div className="bg-black text-white md:p-12 p-4 max-w-[1280px] mx-auto">
      {/* Section Header */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="md:text-4xl text-xl font-bold md:mb-6">Our Products</h2>
        <div className="md:w-[110px] gap-2 flex items-center justify-between">
          {/* Prev Button */}
          <button
            onClick={handlePrev}
            className="md:w-12 w-8 md:h-12 h-8 flex items-center justify-center rounded-full bg-gradient-to-r from-teal-400 to-blue-600 hover:scale-110 transition-transform"
          >
            <i class="fa-solid fa-arrow-left"></i>
          </button>

          {/* Next Button */}
          <button
            onClick={handleNext}
            className="md:w-12 w-8 md:h-12 h-8 flex items-center justify-center rounded-full bg-gradient-to-r from-teal-400 to-blue-600 hover:scale-110 transition-transform"
          >
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>

      {/* Products Buttons */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
        {products.map((product, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`px-4 py-3 rounded-lg font-medium border-2 text-center ${index === currentIndex
              ? "bg-gradient-to-r from-teal-400 to-blue-600 text-white"
              : "border-teal-400 text-teal-400"
              } hover:scale-105 transition-transform`}
          >
            {product.title}
          </button>
        ))}
      </div>

      {/* Selected Product Content */}
      <div className="flex items-center justify-between">
        {/* Product Description */}
        <div className="md:flex items-center gap-8 bg-gray-800 md:p-10 p-4 rounded-lg flex-1">
          <div className="flex-1 max-w-[775px]">
            <h3 className="md:text-2xl text-xl text-start font-bold mb-4">{title}</h3>
            {description.map((paragraph, index) => (
              <p key={index} className="text-gray-300 text-start mb-2">
                {paragraph}
              </p>
            ))}
          </div>
          <img
            src={image}
            alt={title}
            className="h-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
