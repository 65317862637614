import React, { useEffect } from "react";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitch,
  Twitter,
  Youtube,
} from "react-feather";

const Footer = () => {
  useEffect(() => {
    var currentYear = new Date().getFullYear();

    // Find the element with the id "year"
    var yearElement = document.getElementById("year");

    // Update the content of the element with the current year
    yearElement.textContent = currentYear;
  }, []);

  return (
    <>
      {/* <div
        id="contact"
        className="container mx-auto md:pt-20 pt-10 pb-10 md:flex justify-center items-center md:px-20"
      >
        <div className="md:w-2/4 mx-3 md:mx-0 flex justify-center">
          <div>
            <p className="text-3xl font-bold pb-8">Contact Us</p>
            <div className="flex mb-6">
              <div>
                <i class="text-2xl me-6 fa-solid fa-location-dot"></i>
              </div>
              <div>
                <p className="md:text-xl">
                  No(803), Innwa Street, 6th quarter, <br /> South Oakkalar,
                  Yangon Region
                </p>
              </div>
            </div>
            <div className="flex mb-6">
              <div>
                <i class="text-2xl me-6 fa-solid fa-phone-volume"></i>
              </div>
              <div>
                <p className="md:text-xl">(+95) 95182755</p>
              </div>
            </div>
            <div className="flex mb-6">
              <div>
                <i class="text-2xl me-6 fa-solid fa-solid fa-envelope"></i>
              </div>
              <div>
                <p className="md:text-xl">momicheladieswears@gmail.com</p>
              </div>
            </div>

            <p className="text-2xl font-bold pb-8 flex">
              <div className="me-8">Follow Us:</div>
              <div>
                <a href="https://www.facebook.com/momicheladieswear?mibextid=LQQJ4d">
                  <i class="text-2xl me-4 fa-brands fa-facebook"></i>
                </a>
                <a href="#">
                  <i class="text-2xl me-4 fa-brands fa-square-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@yadanara61?_t=8iWTPQFpVe4&_r=1">
                  <i class="text-2xl me-4 fa-brands fa-tiktok"></i>
                </a>
              </div>{" "}
            </p>
          </div>
        </div>
        <div className=" md:w-2/4 flex justify-center">
          <div className="md:w-[350px]">
            <a
              href="/"
              className="md:justify-center space-x-3 rtl:space-x-reverse relative"
            >
              <div className="h-[90px] w-12 rounded-lg bg-black flex justify-center my-2">
                <img src="./logo.png" alt="momiche Logo" />
              </div>
            </a>
            <div>
              <p>We're open</p>
              <p className="font-bold text-xl">from 10 AM to 5:30 PM</p>
              <button
                style={{ background: "red" }}
                type="button"
                className="text-white mt-2 w-[210px] bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Closed Every Monday!
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div
        style={{ margin: "auto" }}
        className="lg:flex flex-wrap md:pt-8 pt-4 container px-4 lg:px-[80px] md:px-[40px] max-w-[1500px] lg-mx-auto"
      >
        <div className="lg:w-[28%] text-wrap mb-4 lg:mb-0">
          <p className="text-[24px] font-bold pb-4">Who we are?</p>
          <p className="text-[16px] text-[#C2C7D6]">
            We are the combination of young plus talented and experienced within
            the team.
          </p>
          <div className="flex gap-4 pt-4">
            <a href="https://www.linkedin.com/company/connectedbusinesssolutions/mycompany/">
              <Linkedin />
            </a>
            <a href="https://www.facebook.com/connectedbusinesssolution">
              <Facebook />
            </a>
          </div>
          {/* <img
            src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/logo.svg"
            className="h-18"
            alt="Flowbite Logo"
          /> */}
          {/* <p className="flex">
            <span>
              <i class="pr-4 fa-solid fa-phone text-3xl"></i>
            </span>
            <span className="text-[1.2rem]">(+95) 9887288807 </span>{" "}
          </p>
          <p className="flex">
            <span>
              <i class="pr-4 fa-regular fa-envelope text-3xl"></i>
            </span>
            <span className="text-[1.2rem]">support@renemattias.com</span>
          </p> */}
        </div>
        <div className="lg:w-[22%] lg:ps-20">
          <p className="text-xl pb-3 cursor-pointer">
            <a href="/">Home</a>
          </p>
          <ul className="text-[#C2C7D6] text-[16px]">
            <li style={{ fontSize: "16px" }} className="py-2 cursor-pointer">
              <a href="/service">Service</a>
            </li>
            <li style={{ fontSize: "16px" }} className="py-2 cursor-pointer">
              <a href="/product">Product</a>
            </li>
            <li style={{ fontSize: "16px" }} className="py-2 cursor-pointer">
              <a href="/industries">Industries</a>
            </li>
            {/* <li style={{ fontSize: "16px" }} className="py-2">
              Sales
            </li> */}
          </ul>
        </div>
        <div className="lg:w-[25%] lg:ps-5">
          <p className="text-xl pb-3">
            <a href="/about">About</a>
          </p>

          <ul className="text-[#C2C7D6] text-[16px]">
            <li style={{ fontSize: "16px" }} className="py-2 text-[1rem]">
              <a href="/about/#ourMission">Our Mission</a>
            </li>
            <li style={{ fontSize: "16px" }} className="py-2 text-[1rem]">
              <a href="/about/#ourVision">Our Vision</a>
            </li>
            <li style={{ fontSize: "16px" }} className="py-2 text-[1rem]">
              <a href="/about/#portfolio">Portfolio</a>
            </li>
            <li style={{ fontSize: "16px" }} className="py-2 text-[1rem]">
              Blogs
            </li>
            {/* <li className="py-2 text-[1rem]">Fit & size</li>
            <li className="py-2 text-[1rem]">Check my wallet balance</li> */}
          </ul>
        </div>
        <div className="lg:w-[25%]">
          <p className="text-xl pb-3">Contact</p>

          <p className="flex pb-2">
            <span>
              <i class="pr-4 fa-solid fa-phone text-xl"></i>
            </span>
            <span className="text-[16px] text-[#C2C7D6]">
              (+95) 9 787 663799
            </span>{" "}
          </p>
          <p className="flex pb-2">
            <span>
              <i class="pr-4 fa-regular fa-envelope text-xl"></i>
            </span>
            <span className="text-[16px] text-[#C2C7D6]">
              info@connected.com.mm
            </span>
          </p>
          <p className="flex pb-2">
            <span>
              <i class="fa-solid fa-location-dot text-xl pr-4"></i>
            </span>
            <span className="text-[16px] text-[#C2C7D6]">
              No(A9), 1st Floor, Shwe Kabar Housing, Min Dahma Road, Mayangone
              Township, Yangon, Myanmar
            </span>
          </p>
        </div>
      </div>

      <div class="text-white md:text-center text-center container md:py-8 py-4">
        <p class="md:text-[14px]" id="copyright-year">
          © <span id="year"></span>{" "}
          <span>
            <a href="https://ethicaldigit.com/">Connected Group.</a>
          </span>{" "}
          All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
