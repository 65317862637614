import React, { useState } from "react";

const ServicesSection = () => {
  const services = [
    {
      title: "Website Development",
      description: [
        "Our technology solutions streamline and optimize business operations, automating processes, enhancing efficiency, and improving overall productivity. From seamless inventory management to streamlined customer relationship management, our solutions empower businesses to operate at their peak potential, saving time and resources while maximizing profitability.",
        "Static Websites, Dynamic Websites, Content Management Systems (CMS), Web Applications, Responsive Websites",
      ],
      image: "/photos/servicePhotos/web-development.png",
    },
    {
      title: "Application Development",
      description: [
       "Application development refers to the process of creating software applications for specific purposes, such as business productivity, communication, entertainment, education, or gaming. It involves several stages, from conceptualization and planning to design, development, testing, deployment, and maintenance.",
       "Application development can be tailored to various platforms and technologies, including web applications, mobile apps (iOS, Android), desktop applications, and hybrid or cross-platform applications. "
    ],
      image: "/photos/servicePhotos/application-development.png",
    },
    {
      title: "E-commerce Development",
      description: [
       `E-commerce refers to the buying and selling of goods or services over the internet. 
It involves the transaction of money, data, and services between buyers and sellers through E-commerce, a wide range of activities, including online retail , B2B, B2C, payment and supply chain management.`,
        "Business-to-Business (B2B) e-commerce refers to the online exchange of goods, services, or information between businesses. ",
        "Business-to-Consumer (B2C) e-commerce, where businesses sell products or services directly to consumers, B2B e-commerce involves transactions between businesses, such as manufacturers, wholesalers, distributors, and retailers.",
    ],
      image: "/photos/servicePhotos/ecommerce-development.png",
    },
    {
      title: "Cloud Management",
      description: [
       "Cloud managed services entail the complete or partial management and control of a client's cloud resources, including tasks such as migration, optimization, security, configuration, system upgrades, and maintenance in a cloud-native modernization powered by our AWS Certified DevOps Engineers and Solutions Architects."
    ],
      image: "/photos/servicePhotos/cloud-management.png",
    },
    {
      title: "Data Center & Virtualization",
      description: [
        "We offer comprehensive data center and virtualization services tailored to meet your needs, whether local, cloud, or hybrid. With a team of certified professional engineers, we ensure seamless implementation and management of your infrastructure, empowering your business to leverage the full potential of technology. Trust us to optimize your data center operations and provide reliable virtualization solutions that drive innovation and growth."
    ],
      image: "/photos/servicePhotos/data-center.png",
    },
    {
      title: "System Integration",
      description: [
        "System integration involves connecting various IT systems, software applications, and services to function cohesively as a unified system, facilitating seamless communication and functionality across disparate platforms."
    ],
      image: "/photos/servicePhotos/system-integration.png",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? services.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === services.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { title, description, image } = services[currentIndex];

  return (
    <div className="bg-black text-white md:p-12 p-4 max-w-[1280px] mx-auto">
      {/* Section Header */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="md:text-4xl text-xl font-bold md:mb-6">Our Services</h2>
        <div className="md:w-[110px] gap-2 flex items-center justify-between">
          {/* Prev Button */}
          <button
            onClick={handlePrev}
            className="md:w-12 w-8 md:h-12 h-8 flex items-center justify-center rounded-full bg-gradient-to-r from-teal-400 to-blue-600 hover:scale-110 transition-transform"
          >
            <span className="text-white text-2xl"><i class="fa-solid fa-arrow-left"></i></span>
          </button>

          {/* Next Button */}
          <button
            onClick={handleNext}
            className="md:w-12 w-8 md:h-12 h-8 flex items-center justify-center rounded-full bg-gradient-to-r from-teal-400 to-blue-600 hover:scale-110 transition-transform"
          >
            <span className="text-white text-2xl"><i class="fa-solid fa-arrow-right"></i></span>
          </button>
        </div>
      </div>

      {/* Services Buttons */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
        {services.map((service, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`px-4 py-3 rounded-lg font-medium border-2 text-center ${
              index === currentIndex
                ? "bg-gradient-to-r from-teal-400 to-blue-600 text-white"
                : "border-teal-400 text-teal-400"
            } hover:scale-105 transition-transform`}
          >
            {service.title}
          </button>
        ))}
      </div>

      {/* Selected Service Content */}
      <div className="flex items-center justify-between">
        {/* Service Description */}
        <div className="md:flex items-center gap-8 bg-gray-800 md:p-10 p-4 rounded-lg flex-1">
          <div className="flex-1 max-w-[775px]">
            <h3 className="md:text-2xl text-xl text-start font-bold mb-4">{title}</h3>
            {description.map((paragraph, index) => (
              <p key={index} className="text-gray-300 text-start mb-2">
                {paragraph}
              </p>
            ))}
          </div>
          <img
            src={image}
            alt={title}
            className="h-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
