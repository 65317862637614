import React from "react";

const SpecialistSection = () => {
  const teams = [
    { title: "Project Managers", icon: "👥" },
    { title: "Frontend Team", icon: "👥" },
    { title: "Backend Team", icon: "👥" },
    { title: "Mobile Team", icon: "👥" },
    { title: "QA Team", icon: "👥" },
    { title: "UI/UX Team", icon: "👥" },
    { title: "SI & Cloud Team", icon: "👥" },
  ];

  return (
    <div className="bg-black text-white md:p-12 p-4 max-w-[1280px] mx-auto">
      <div className="container mx-auto text-center">
        <h2 className="md:text-3xl text-xl font-bold mb-4">Our Specialist in</h2>
        <p className="md:text-lg mb-12">
          We are a combination of young talent and experienced professionals within the team.
        </p>
        <div className="flex justify-center flex-wrap gap-6">
          {teams.map((team, index) => (
            <div
              key={index}
              className="border border-teal-400 rounded-lg md:p-6 py-4 md:w-[250px] w-[45%] hover:scale-105 transition-transform duration-300 flex flex-col justify-center items-center text-center"
            >
              <div className="text-5xl mb-4">
                <img src="/people.png" alt="" className="w-12 h-12" />
              </div>
              <h3 className="md:text-xl font-semibold">{team.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecialistSection;
