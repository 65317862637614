import React from "react";

const ClientGrid = () => {
  const clients = [
    { id: 1, name: "Client 1", logo: "/photos/clientLogos/1.png" },
    { id: 2, name: "Client 2", logo: "/photos/clientLogos/2.png" },
    { id: 3, name: "Client 3", logo: "/photos/clientLogos/3.png" },
    { id: 4, name: "Client 4", logo: "/photos/clientLogos/4.png" },
    { id: 5, name: "Client 5", logo: "/photos/clientLogos/5.png" },
    { id: 6, name: "Client 6", logo: "/photos/clientLogos/6.png" },
    { id: 7, name: "Client 7", logo: "/photos/clientLogos/7.png" },
    { id: 8, name: "Client 8", logo: "/photos/clientLogos/8.png" },
    { id: 9, name: "Client 9", logo: "/photos/clientLogos/9.png" },
    { id: 10, name: "Client 10", logo: "/photos/clientLogos/10.png" },
    { id: 11, name: "Client 11", logo: "/photos/clientLogos/11.png" },
    { id: 12, name: "Client 12", logo: "/photos/clientLogos/12.png" },
    { id: 13, name: "Client 13", logo: "/photos/clientLogos/13.png" },
    { id: 14, name: "Client 14", logo: "/photos/clientLogos/14.png" },
    { id: 15, name: "Client 15", logo: "/photos/clientLogos/15.png" },
    { id: 16, name: "Client 16", logo: "/photos/clientLogos/16.png" },
    { id: 17, name: "Client 17", logo: "/photos/clientLogos/17.png" },
    { id: 18, name: "Client 18", logo: "/photos/clientLogos/18.png" },
    { id: 19, name: "Client 19", logo: "/photos/clientLogos/19.png" },
    { id: 20, name: "Client 20", logo: "/photos/clientLogos/20.png" },
  ];

  return (
    <div className="bg-black text-white py-12 max-w-[1280px] mx-auto">
      <h2 className="text-center md:text-3xl text-xl font-bold mb-8">
        Several Of Our Valued Clients
      </h2>

      {/* Dynamic Client Grid */}
      <div className="flex flex-wrap justify-around md:gap-4 gap-2 border p-8 mx-4 md:mx-0 rounded-[25px]">
        {clients.map((client) => (
          <div
            key={client.id}
            className="rounded-lg flex items-center"
          >
            <img
              src={client.logo}
              alt={client.name}
              className="md:w-full w-[60px] h-auto object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientGrid;
